import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from '../commons/Logger';
import { substitutePlaceholders, substitutePlaceholdersWithoutDollar } from '../commons/HelperFunctions';
import { EventType, Locale, QueryParam, Settings, System } from '../commons/Constants';
import postEvent, { createBaseLanderEvent } from '../commons/LanderEvent';
import { FormattedMessage } from 'react-intl';
import { getContent } from '../adsense/content/Content';

export const BannerType = {
  AUCTION: 'AUCTION',
  BACKORDER: 'BACKORDER',
  DBS: 'DBS',
  FOR_SALE: 'FOR_SALE',
  USER_DEFINED: 'USER_DEFINED'
};

/* eslint-disable no-template-curly-in-string */
export const BannerDefaults = {
  SN: {
    link: '/forsale.html',
    text: '${domain} may be for sale!'
  },
  CP: {
    link:
      'https://mcc.godaddy.com/DomainInterest/DomainInterest.aspx?domain=${encryptedDomain}',
    text: 'Interested in this domain?',
    AUCTION: {
      link:
        'https://auctions.godaddy.com/trpItemListing.aspx?ci=6711&domain=${domain}',
      text: 'This domain is available on GoDaddy Auctions'
    }
  }
};

/**
 * Simple logic to show/hide banner
 * Query param "?tdfs=1/0" always overrides any database settings.
 * If query parameter `tdfs` is not set to a boolean value, check the links from the lander config
 * @param landerConfig
 * @param queryConfig
 * @returns boolean
 */
export function displayBanner(landerConfig, queryConfig) {
  // check if tdfs is enabled via query param
  if (typeof queryConfig[QueryParam.TDFS] === 'boolean') {
    return queryConfig[QueryParam.TDFS];
  }

  // check if at least one of the links can be displayed or else return false
  return (
    isBannerEnabled(landerConfig) || hasAuction(landerConfig) // Auction is CP specific
  );
}

/**
 * Is banner enabled for this domain
 * @param landerConfig
 * @returns boolean
 */
export function isBannerEnabled(landerConfig) {
  // check against undefined keys
  return !!((landerConfig.lander || {}).banner || {}).show;
}

/**
 * Do we have all the elements to create a 'GoDaddy Auctions' link?
 * This applies to CP only
 * For SN, this would always return undefined, which would be coerced to false when used laster
 */
function hasAuction(landerConfig) {
  return !!landerConfig.domain.hasAuction;
}

/**
 * Do we have all the elements to create a 'Banner Link'?
 * This applies for both CP and SN
 */
function hasBannerElements(landerConfig) {
  const { link, text, type } = { ...(landerConfig.lander || {}).banner };
  return link && text && type;
}

/**
 * getBanner returns a banner object.
 *
 * @param landerConfig
 * @param content
 * @returns {{}}
 */
export function getBanner(landerConfig, content) {
  var bannerText = getContent(landerConfig.lander.template, landerConfig.system, Locale.EN_US);
  const banner = {
    link: null,
    text: null,
    type: null
  };
  // Domains is pre-configured with all banner elements and is not a ParkWeb
  if (hasBannerElements(landerConfig) && landerConfig.system !== System.PARKWEB) {
    var existedBanner = landerConfig.lander.banner;
    banner.link = existedBanner.link;
    banner.type = existedBanner.type;

    if (landerConfig.system === System.CASHPARKING) {
      if(!existedBanner.text.localeCompare(bannerText.cashparkingText, undefined, { sensitivity: 'accent' })){
        banner.text = <FormattedMessage id ='cashparkingText' />
        return banner;
      }
    }

    if (landerConfig.system === System.SMARTNAME) {
      const phoneRegexp = /(\+\d{1,2}\s)?\d{3}[-]\d{3}[-]\d{4}/g;
      const siteNameRegexp = /(Afternic)(.com)?/g;
      var phoneMatches = existedBanner.text.match(phoneRegexp);
      var siteMatch = existedBanner.text.match(siteNameRegexp);
      var phone1 = "";
      var phone2 = "";
      var siteName = "";
      if(phoneMatches != null){
        phone1 = phoneMatches[0];
        phone2 = phoneMatches[1];
      }
      if(siteMatch != null){
        siteName = siteMatch[0];
      }
      var defaultTdfsBannerText = substitutePlaceholdersWithoutDollar(bannerText.tdfsText, {
        domain: landerConfig.domain.rootDomain,
        afternicSiteName: siteName,
        afternicPhone1: phone1,
        afternicPhone2: phone2,
      })

      var defaultBannerText = substitutePlaceholdersWithoutDollar(bannerText.contactMeText, {
        domain: landerConfig.domain.rootDomain,
      })

      if(!existedBanner.text.localeCompare(defaultTdfsBannerText, undefined, { sensitivity: 'accent' })){
        banner.text = <FormattedMessage id ='tdfsText' values={{
          domain: landerConfig.domain.rootDomain,
          afternicSiteName: siteName,
          afternicPhone1: phone1,
          afternicPhone2: phone2,
        }} />
        return banner;
      }

      if(!existedBanner.text.localeCompare(defaultBannerText, undefined, { sensitivity: 'accent' })){
        banner.text = <FormattedMessage id ='contactMeText' values={{
          domain: landerConfig.domain.rootDomain,
        }} />
        return banner;
      }
    }
    banner.text = existedBanner.text;
    return banner;
  }

  // Domain does not have banner element, create a default banner

  // For CP, if has auction, show auction link
  if (landerConfig.system === System.CASHPARKING && hasAuction(landerConfig)) {
    banner.type = BannerType.AUCTION;
    banner.text = bannerText.auctionBannerText;
    banner.link = substitutePlaceholders(BannerDefaults.CP.AUCTION.link, {
      domain: landerConfig.domain.rootDomain
    });
    return banner;
  }

  // Create system default banner. Using `FOR_SALE` as a default type.
  Logger.debug('Creating default banner for ' + landerConfig.system);
  banner.type = landerConfig.lander.banner.type || BannerType.FOR_SALE;

  switch (landerConfig.system) {
    case System.CASHPARKING:
      bannerText = getContent(landerConfig.lander.template,"CP")
      banner.text = bannerText.cashparkingText;
      banner.link = substitutePlaceholders(BannerDefaults.CP.link, {
        encryptedDomain: landerConfig.domain.encryptedDomain
      });
      break;

    case System.SMARTNAME:
      bannerText = getContent(landerConfig.lander.template,landerConfig.system)
      banner.text = substitutePlaceholdersWithoutDollar(bannerText.contactMeText, {
        domain: landerConfig.domain.rootDomain
      });
      banner.link = BannerDefaults.SN.link;
      break;

    case System.PARKWEB:
      bannerText = getParkWebBannerText(landerConfig, content);
      banner.text = substitutePlaceholders(bannerText, {
        domain: landerConfig.domain.rootDomainDisplayText
      });
      banner.link = (landerConfig.lander.banner || {}).link;
      break;

    default:
      break;
  }

  return banner;
}

export function getParkWebBannerText(landerConfig, content) {
  const bannerType = ((landerConfig.lander || {}).banner || {}).type;
  const expired = (landerConfig.domain || {}).expired;
  switch (bannerType) {
    case BannerType.DBS:
      return expired ? content.expiredDbsText : content.dbsText;
    case BannerType.AUCTION:
      return expired ? content.expiredAuctionText : content.auctionText;
    case BannerType.BACKORDER:
      return expired ? content.expiredBackorderText : content.backorderText;
    default: return '';
  }
}

export function Banner(props) {
  // check if tdfs banner should be displayed
  if (!displayBanner(props.landerConfig, props.queryConfig)) {
    Logger.debug('tdfs banner is disabled');
    return null;
  }

  const banner = getBanner(props.landerConfig, props.content);

  // onClick handler for banner clicks
  const bannerClick = function (bannerType) {
    sendBannerClickEvent(bannerType, props.landerConfig);
  };

  return (
    <div>
      <div id='tdfsBorder' />
      <div id='tdfsBanner'>
        <div id='tdfsLink'>
          <a href={ banner.link } onClick={ () => bannerClick(banner.type) }>
            {banner.text}
          </a>
        </div>
      </div>
    </div>
  );
}

Banner.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired
};

/**
 * Send Banner (tdfs) Click event
 * @param bannerType
 * @param landerConfig
 */
export async function sendBannerClickEvent(bannerType, landerConfig) {
  const bannerClickEvent = createBaseLanderEvent(
    EventType.BANNER_CLICK,
    landerConfig
  );
  bannerClickEvent.bannerType = bannerType;
  postEvent(Settings.EVENT_PUBLISH_API, bannerClickEvent);
}
