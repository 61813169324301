import CssRule, { cssRule, cssRules } from '../../commons/CssRule';
import React from 'react';
import { GoogleCaf } from '../AdSense';

export function getParkWebPageLevel() {
  return {
    uiOptimize: true,
    styleId: GoogleCaf.styleId.PARK_WEB
  };
}

export function getParkWebRelatedLinks(mobile) {
  const obj = {
    attributionUppercase: true,
    attributionSpacingBelow: '6',
    attributionBold: true,
    webFontFamilyAttribution: 'GD Sherpa',
    fontFamilyAttribution: 'GD Sherpa,arial',
    fontSizeAttribution: '13',
    colorAttribution: '#111111',
    webFontFamily: 'GD Sherpa',
    fontFamily: 'GD Sherpa,arial',
    titleBold: false,
    fontSizeTitle: '18',
    lineHeightTitle: '50',
    noTitleUnderline: true,
    adIconUrl: 'https://www.gstatic.com/domainads/images/chevron-white.png',
    adIconHeight: '18', // Required (if adIconUrl is set)
    adIconWidth: '18', // Required (if adIconUrl is set)
    adIconSpacingAbove: '10',
    adIconSpacingAfter: '10',
  };

  if (mobile) {
    obj.lineHeightTitle = '30';
  }

  return obj;
}

export function commonRules() {
  return cssRules([new CssRule(
    'body', [
      'margin: 0;',
      'padding: 0;'
    ]), new CssRule(
      '#contentLayout', [
        'color: #111111;',
        'font-family: GD Sherpa,arial;'
      ]), new CssRule(
        '#domainBanner', [
          'background-color: #D8EEEF;',
          'margin-bottom: 20px;'
        ]), new CssRule(
          '#gdLogo', [
            'padding-left: 10%;',
            'padding-top: 20px;'
          ]), new CssRule(
            '#domain', [
              'font-size: 48px;',
              'font-weight: 600;',
              'line-height: 60px;',
              'text-align: center;',
              'padding-bottom: 5px;'
            ]), new CssRule(
              '#domainInfo', [
                'font-size: 20px;',
                'font-weight: 600;',
                'line-height: 28px;',
                'text-align: center;',
                'padding-bottom: 5px;'
              ]), new CssRule(
                '#getButton', [
                  'padding-top: 10px;',
                  'padding-bottom: 20px;'
                ]), new CssRule(
                  '#getButtonBox', [
                    'background-color: #111111;',
                    'color: white;',
                    'font-size: 16px;',
                    'width: 248px;',
                    'margin: auto;',
                    'text-align: center;'
                  ]), new CssRule(
                    '#getButtonBoxLink', [
                      'display: block;',
                      'line-height: 50px;',
                      'text-decoration: none;',
                      'font-weight: bold;',
                      'color: white'
                    ]), new CssRule(
                      '#relatedLinks, #ads', [
                        'margin: auto;',
                        'border: solid #111111 1px;',
                        'background-color: #FFFFFF;',
                        'height: auto;'
                      ]), new CssRule(
                        '#copyright', [
                          'padding-top: 50px;',
                          'min-width: 1000px;',
                          'max-width: 1200px;',
                          'text-align: center;',
                          'font-size: 16px;',
                          'margin: auto;',
                          'margin-bottom: 20px;'
                        ]), new CssRule(
                          '#privacy a', [
                            'color: #111;',
                            'text-decoration: none;'
                          ]), new CssRule(
                            '#privacy a:hover', [
                              'color: #111;',
                              'text-decoration: underline;'
                            ])
  ]);
}

export function ParkWeb() {
  return <style>
    {commonRules()}
    {cssRule('#relatedLinks, #ads', [
      'max-width: 1230px;',
      'border: solid #111111 1px;',
      'padding: 16px 32px 32px;'
    ])}
  </style>;
}

export function ParkWebMobile() {
  return <style>
    {commonRules()}
    {cssRule('#gdLogo', [
      'padding-left: 0;',
      'padding-top: 17px;',
      'text-align: center;'
    ])}
    {cssRule('#domainBanner', [
      'margin-bottom: 10px;',
      'padding-bottom: 10px;'
    ])}
    {cssRule('#domain', [
      'font-size: 24px;',
      'font-weight: 500;',
      'line-height: 28px;',
      'margin-top: 12px;'
    ])}
    {cssRule('#domainInfo', [
      'padding-left: 5px;',
      'padding-right: 5px;',
      'margin-top: 7px;'
    ])}
    {cssRule('#getButton', [
      'padding-bottom: 10px;'
    ])}
    {cssRule('#getButtonBox', [
      'width: 340px;'
    ])}
    {cssRule('#getButtonBoxLink', [
      'font-weight: normal;'
    ])}
    {cssRule('#relatedLinks, #ads', [
      'width: 360px;',
      'border: solid #fff 1px;',
      'padding: 0;'
    ])}
    {cssRule('#copyright', [
      'min-width: 0;',
      'margin-top: 50px;',
      'width: 346px;',
      'text-align: center;',
      'font-size: 13px;',
      'line-height: 20px;',
      'margin: auto;',
      'margin-left: 20px;',
      'margin-right: 20px;',
      'margin-bottom: 20px;'
    ])}
  </style>;
}
