import { LanderCookie, QueryParam } from './Constants';
import { getCookie, isEmptyOrUndefined, parseBoolean } from './HelperFunctions';
import { getLanderTemplate } from '../adsense/AdSense';
import qs from 'qs';

// URL query fields of type boolean (0,1,true,false)
const BooleanQueryFields = [
  QueryParam.SHOW_DOMAIN,
  QueryParam.TDFS,
  QueryParam.BACK_FILL,
  QueryParam.SEARCH_BOX,
  QueryParam.SEARCH_BUTTON,
  QueryParam.DEBUG_MODE
];

/**
 * Parses lander URL query parameters. Parse boolean values (0,1,true,false)
 * @returns {{}}
 */
export function parseLanderQueryParam(queryString) {
  const queryParam = qs.parse(queryString, {
    ignoreQueryPrefix: true, decoder: function (str, defaultDecoder, charset, type) {
      if (type === 'key') {
        return str.toLowerCase();
      } else if (type === 'value') {
        return defaultDecoder(str, null, charset);
      }
    }
  });

  if (isEmptyOrUndefined(queryParam)) {
    return {};
  }

  // Parse all boolean query field
  BooleanQueryFields.forEach(function (field) {
    if (queryParam.hasOwnProperty(field)) {
      queryParam[field] = parseBoolean(queryParam[field]);
    }
  });

  return queryParam;
}

/**
 * Create a new lander object using fields from both Lander API and Query parameters.
 * Some query parameters overrides the lander API result.
 * @param landerConfig
 * @param queryConfig
 * @return object
 */
export function createLanderConfig(landerConfig, queryConfig) {
  const lander = {
    account: landerConfig.account,
    portfolioId: landerConfig.portfolioId,
    dataSource: landerConfig.dataSource,
    displayType: landerConfig.displayType,
    rotatorId: landerConfig.rotatorId,
    system: landerConfig.system
  };

  // Domain
  const domainObj = landerConfig.domain || {};
  lander.domain = {
    rootDomain: domainObj.rootDomain,
    rootDomainDisplayText: domainObj.rootDomainDisplayText,
    rootDomainFromBrowser: domainObj.rootDomainFromBrowser,
    expiresAt: domainObj.expiresAt,
    expired: domainObj.expired,
    encryptedDomain: domainObj.encryptedDomain,
    status: {
      internal: (domainObj.status || {}).internal
    },
    isAdult: domainObj.isAdult,
    hasAuction: domainObj.hasAuction
  };

  // AdSense
  if (landerConfig.adSense) {
    lander.adSense = {
      channel: landerConfig.adSense.channel,
      drid: landerConfig.adSense.drid,
      pubId: landerConfig.adSense.pubId,
      keywords: queryConfig[QueryParam.TERM] || landerConfig.adSense.keywords
    };
  }

  // Experiment
  if (landerConfig.experiment) {
    lander.experiment = {
      key: landerConfig.experiment.experiment,
      bucket: landerConfig.experiment.cohort,
      start: landerConfig.experiment.start,
      end: landerConfig.experiment.end
    };
  }

  // Lander
  if (landerConfig.lander) {
    lander.lander = {
      template: getLanderTemplate(landerConfig, queryConfig),
      domainDisplayName: landerConfig.lander.domainDisplayName,
      headerText: landerConfig.lander.headerText,
      headerHtml: landerConfig.lander.headerHtml,
      footerText: landerConfig.lander.footerText,
      footerHtml: landerConfig.lander.footerHtml,
      eventCallbackUrl: landerConfig.lander.eventCallbackUrl,
      showDomain: landerConfig.lander.showDomain,
      i18n: landerConfig.lander.i18n
    };
  }

  // Lander: Banner
  if ((landerConfig.lander || {}).banner) {
    lander.lander.banner = {
      show: typeof queryConfig[QueryParam.TDFS] === 'boolean' ? queryConfig[QueryParam.TDFS] : landerConfig.lander.banner.show,
      type: landerConfig.lander.banner.type,
      link: landerConfig.lander.banner.link,
      text: landerConfig.lander.banner.text
    };
  }

  // Turnkey reseller
  if (landerConfig.turnKeyReseller) {
    lander.turnKeyReseller = {
      displayName: landerConfig.turnKeyReseller.displayName,
      programId: landerConfig.turnKeyReseller.programId,
      privateLabelId: landerConfig.turnKeyReseller.privateLabelId,
      privacyUrl: landerConfig.turnKeyReseller.privacyUrl,
      redirectUrl: landerConfig.turnKeyReseller.redirectUrl
    };
  }

  return lander;
}

// Returns expiry partner value from cookie (format: expiryPartner.portfolioId)
export function getExpiryPartner() {
  const expiryPartner = getCookie(LanderCookie.EXPIRY_PARTNER);
  return expiryPartner ? expiryPartner.split('.')[0] : '';
}

// Returns expiry partner portfolio id from cookie (format: expiryPartner.portfolioId)
export function getExpiryPartnerPortfolioId() {
  const expiryPartner = getCookie(LanderCookie.EXPIRY_PARTNER);
  if (!expiryPartner) {
    return '';
  }
  const token = expiryPartner.split('.');
  return token.length > 1 ? token[1] : '';
}
