import React from 'react';
import { getLocaleFromBrowser, getLocalizedMessages } from '../../i18n/locale.js';
import { AdSense } from '../AdSense.js';
import { System } from '../../commons/Constants'

import { createIntl } from 'react-intl';


export const Content = {
  PARK_WEB_AUCTIONS_TEXT: {
    en_US: 'is available on GoDaddy Auctions.'
  },
  PARK_WEB_EXPIRED_AUCTIONS_TEXT: {
    en_US: 'has expired and is available on GoDaddy Auctions.'
  },
  PARK_WEB_DBS_TEXT: {
    en_US: 'is parked free, courtesy of GoDaddy.'
  },
  PARK_WEB_EXPIRED_DBS_TEXT: {
    en_US: 'has expired and is parked free, courtesy of GoDaddy.'
  },
  SIMPLE_LAYOUT_AUCTIONS_TEXT: {
    en_US: '${domain} is for sale on GoDaddy Auctions. Click here for more details.'
  },
  SIMPLE_LAYOUT_EXPIRED_AUCTIONS_TEXT: {
    en_US: '${domain} has expired and is for sale on GoDaddy Auctions. Click here for more details.'
  },
  SIMPLE_LAYOUT_DBS_TEXT: {
    en_US: 'Interested in ${domain}? Our Domain Broker Service may be able to get it for you. Find out how.'
  },
  SIMPLE_LAYOUT_EXPIRED_DBS_TEXT: {
    en_US: '${domain} has expired. Our Domain Broker Service may be able to get it for you. Find out how.'
  },
  SIMPLE_LAYOUT_BACKORDER_TEXT: {
    en_US: 'Interested in ${domain}? Find out how to get it through Domain Backorder.'
  },
  SIMPLE_LAYOUT_EXPIRED_BACKORDER_TEXT: {
    en_US: '${domain} has expired. Find out how to get it through Domain Backorder.'
  }
};

const templateContent = {
  SIMPLE_2C: {
      auctionText: Content.SIMPLE_LAYOUT_AUCTIONS_TEXT.en_US,
      expiredAuctionText: Content.SIMPLE_LAYOUT_EXPIRED_AUCTIONS_TEXT.en_US,
      dbsText: Content.SIMPLE_LAYOUT_DBS_TEXT.en_US,
      expiredDbsText: Content.SIMPLE_LAYOUT_EXPIRED_DBS_TEXT.en_US,
      backorderText: Content.SIMPLE_LAYOUT_BACKORDER_TEXT.en_US,
      expiredBackorderText: Content.SIMPLE_LAYOUT_EXPIRED_BACKORDER_TEXT.en_US
  },

  SIMPLE_2C_LIGHT: {
      auctionText: Content.SIMPLE_LAYOUT_AUCTIONS_TEXT.en_US,
      expiredAuctionText: Content.SIMPLE_LAYOUT_EXPIRED_AUCTIONS_TEXT.en_US,
      dbsText: Content.SIMPLE_LAYOUT_DBS_TEXT.en_US,
      expiredDbsText: Content.SIMPLE_LAYOUT_EXPIRED_DBS_TEXT.en_US,
      backorderText: Content.SIMPLE_LAYOUT_BACKORDER_TEXT.en_US,
      expiredBackorderText: Content.SIMPLE_LAYOUT_EXPIRED_BACKORDER_TEXT.en_US
  },

  LIGHT_BLUE: {
      auctionText: Content.SIMPLE_LAYOUT_AUCTIONS_TEXT.en_US,
      expiredAuctionText: Content.SIMPLE_LAYOUT_EXPIRED_AUCTIONS_TEXT.en_US,
      dbsText: Content.SIMPLE_LAYOUT_DBS_TEXT.en_US,
      expiredDbsText: Content.SIMPLE_LAYOUT_EXPIRED_DBS_TEXT.en_US,
      backorderText: Content.SIMPLE_LAYOUT_BACKORDER_TEXT.en_US,
      expiredBackorderText: Content.SIMPLE_LAYOUT_EXPIRED_BACKORDER_TEXT.en_US
  },

  SIMPLE_2C_EXPERIMENT: {
      auctionsText: Content.SIMPLE_LAYOUT_AUCTIONS_TEXT.en_US,
      expiredAuctionsText: Content.SIMPLE_LAYOUT_EXPIRED_AUCTIONS_TEXT.en_US,
      dbsText: Content.SIMPLE_LAYOUT_DBS_TEXT.en_US,
      expiredDbsText: Content.SIMPLE_LAYOUT_EXPIRED_DBS_TEXT.en_US,
      backorderText: Content.SIMPLE_LAYOUT_BACKORDER_TEXT.en_US,
      expiredBackorderText: Content.SIMPLE_LAYOUT_EXPIRED_BACKORDER_TEXT.en_US

    },

  VERTICAL_LINES: {
      auctionText: Content.SIMPLE_LAYOUT_AUCTIONS_TEXT.en_US,
      expiredAuctionText: Content.SIMPLE_LAYOUT_EXPIRED_AUCTIONS_TEXT.en_US,
      dbsText: Content.SIMPLE_LAYOUT_DBS_TEXT.en_US,
      expiredDbsText: Content.SIMPLE_LAYOUT_EXPIRED_DBS_TEXT.en_US,
      backorderText: Content.SIMPLE_LAYOUT_BACKORDER_TEXT.en_US,
      expiredBackorderText: Content.SIMPLE_LAYOUT_EXPIRED_BACKORDER_TEXT.en_US
  }
};

/**
 * getContent returns all localized content for a given template and locale
 * @param landerConfig
 * @param locale
 * @returns {*}
 */
export function getContent(template, system, lng) {
  const locale = getLocaleFromBrowser();
  const useMessages = getLocalizedMessages(lng?lng:locale);
  const intl = createIntl({ locale, useMessages })
  var content = {}
  if (template ==AdSense.template.PARK_WEB  || system == System.CASHPARKING || system == System.SMARTNAME){
    for(var element in useMessages){
      content[element] = intl.formatMessage({ id: `${useMessages[element]}`, defaultMessage: useMessages[element] })
    }
    return content
  }
  return templateContent[template]
}
